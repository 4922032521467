import React from 'react';
import { graphql } from 'gatsby';

import { TitleAndMetaTags } from '../../components/TitleAndMetaTags/TitleAndMetaTags';
import { Section } from '../../components/Section/Section';
import { Contact } from '../../components/Contact/Contact';
import { FakeImg } from '../../components/FakeImg/FakeImg';
import { Banner } from '../../components/Banner/Banner';

import stockImg from '../../images/stock.jpg';
import fullWidthImageTwoHandsImg from '../../images/full-width-image-twohands.jpg';

const Shop = ({ className, shop }) => {
	return !shop.disabled ? (
		<div className={`col sm-10 md-3 ${className}`}>
			<div>
				{/* <h3>{shop.name}</h3> */}
				<h3>{shop.area}</h3>
				{/* <h4>{shop.area}</h4> */}
				<address>
					{shop.address}
					<br />
					{shop.post_code}
				</address>
				<a
					href={`https://www.google.com/maps/dir/?api=1&destination=${shop.post_code}`}
					className="small faded"
					target="_blank">
					View on map
				</a>
			</div>
		</div>
	) : (
		''
	);
};

const ShopsPage = ({
	data: {
		hasura: { store }
	}
}) => {
	return (
		<div className="main">
			<TitleAndMetaTags title="Shops" pathname="shops" />
			<Section>
				<div className="grid">
					<h1 className="col -block md-push-1 lg-push-2 title">
						Where to buy
						<br />
						<span className="salmon">
							Bake-at-home
							<br />
							frozen packs.
						</span>
					</h1>
				</div>

				<div className="grid shops-list">
					{store.map((data, dataIndex) => {
						return (
							<div>
								<Shop
									key={dataIndex}
									shop={data}
									className={dataIndex === 0 ? 'md-push-2 lg-push-3' : ''}
								/>
							</div>
						);
					})}
				</div>
			</Section>

			<Section className="-blue center">
				<div className="grid">
					<h2>Can't find Best Foods Franchise Pão de Queijo near you?</h2>
					<p>Bring our Product Request Form to your favourite store.</p>
					<a className="button" href="./product-request-form.pdf" target="_blank">
						Get request form
					</a>
				</div>
			</Section>

			<Banner img={fullWidthImageTwoHandsImg} mobileImg={fullWidthImageTwoHandsImg} />

			<Section className="-blue shops-interest">
				<div className="grid">
					<h2 className="h1 col md-push-1 lg-push-2">Interested in stocking?</h2>
				</div>

				<div className="grid">
					<div className="col md-7 lg-5 md-push-1 lg-push-3">
						<p className="measure-wider">
							We're currently growing our network of retailers in London. If you're
							interested in stocking our product at your store, please call Raj on +447376
							421 501 or email us at{' '}
							<a href="mailto:hi@bestfoodsuk.com">hi@bestfoodsuk.com</a>. Distributors
							welcome.
						</p>
					</div>
				</div>

				<div className="grid">
					<div className="col fluid md-7 md-push-1 lg-6 lg-push-2">
						<FakeImg className="-three-x-two" img={stockImg} />
					</div>
				</div>
			</Section>

			<Contact />
		</div>
	);
};

export default ShopsPage;

export const pageQuery = graphql`
	query getAllShops {
		hasura {
			store {
				name
				address
				post_code
				city
				country
				delivery_method
				branch
				area
				phone_1
				phone_2
				open_hours
				disabled
			}
		}
	}
`;
